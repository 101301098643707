@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,900,400italic&subset=latin,latin-ext);
@font-face {
  font-family: RobotoDraft;
  src:
    local('RobotoDraft'),
    local('RobotoDraft-Regular'),
    local('Roboto-Regular'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni4gp9Q8gbYrhqGlRav_IXfk.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/0xES5Sl_v6oyT7dAKuoni7rIa-7acMAeDBVuclsi6Gc.woff) format('woff');
}

@font-face {
  font-family: RobotoDraft;
  font-weight: 500;
  src:
    local('RobotoDraft Medium'),
    local('RobotoDraft-Medium'),
    local('Roboto-Medium'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwXJuJo8UJJfpGKt7pXjBv4s.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwaTA90I55Xt7owhZwpPnMsc.woff) format('woff');
}

@font-face {
  font-family: RobotoDraft;
  font-weight: 700;
  src:
    local('RobotoDraft Bold'),
    local('RobotoDraft-Bold'),
    local('Roboto-Bold'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-Vwf79_ZuUxCigM2DespTnFaw.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/u0_CMoUf3y3-4Ss4ci-VwRbnBKKEOwRKgsHDreGcocg.woff) format('woff');
}

@font-face {
  font-family: RobotoDraft;
  font-style: italic;
  src:
    local('RobotoDraft Italic'),
    local('RobotoDraft-Italic'),
    local('Roboto-Italic'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfgeOulFbQKHxPa89BaxZzA0.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/robotodraft/v1/er-TIW55l9KWsTS1x9bTfoo3ZslTYfJv0R05CazkwN8.woff) format('woff');
}

body {
  margin: 0;
  font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: unset !important;
  font-smooth: unset !important;
}

html {
  -webkit-font-smoothing: unset !important;
  font-smooth: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.toogle {
  height: 28px !important;
  width: 28px !important;
  color: #009587 !important;
  border: none !important;
  margin: auto !important;
}

.toogle:hover {
  border: 2px solid #009587 !important;
  border-radius: 100% !important;
}
.toogle.hidden {
  display: none;
}

@media screen and (max-width: 480px) {
  .navbar-brand4,
  .navbar-brand,
  .toogle {
    display: none;
  }
  .mobile-children {
    width: -webkit-fill-available;
    position: fixed;
    left: 0;
    transition: margin-left 0.4s ease-in-out;
  }
  .children {
    width: -webkit-fill-available;
    position: fixed;
    left: 0;
    transition: margin-left 0.4s ease-in-out;
    height: 91% !important;
  }
  .children.open {
    margin-left: 45px;
  }
  .sidebar {
    transition: transform 0.5s ease-in-out;
    transform: translateY(-135%);
  }
  .sidebar.open {
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);
  }
  .navbarHeader.hidden {
    display: none;
  }

  .navbar-brand4.hidden,
  .navbar-brand5.hidden {
    display: none;
  }
  .navbar-brand4.open,
  .navbar-brand5.open {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .web-table {
    display: none;
  }
}
